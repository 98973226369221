section#portfolio {


    article.container {
        // padding: 150px 1rem 50px;
        // border-bottom: 1px solid rgba(0, 0, 0, .1);

        @media (min-width: 1366px) {
            // & {
            //     padding: 200px 1rem 50px;
            // }
        }

        .wrap {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                max-width: 768px;

                li {
                    width: 33%;
                    display: flex;
                    // flex-direction: column;
                    justify-content: center;
                    // align-items: center;
                    padding: 1rem 0;
                    opacity: 0.7;
                    transition: 0.3s;

                    &>a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    @media (min-width: 768px) {
                        // width: 33%;
                        padding: 1.5rem 0;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &.ambrosus {
                        figure {
                            img {
                                width: 55%;
                            }
                        }
                    }

                    // &.windingtree {
                    //     figure {
                    //         img {
                    //             width: 35%;
                    //         }
                    //     }
                    // }

                    &.rsk {
                        figure {
                            img {
                                transform: translate(10%, -10%);
                            }
                        }
                    }

                    figure {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        width: 80px;
                        height: 80px;
                        cursor: pointer;
                        transition: 0.5s;
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        @media (min-width: 768px) {
                            width: 120px;
                            height: 120px;
                        }

                        img {
                            width: 45%;
                        }
                    }

                    p {
                        // line-height: 2;
                        padding: 1rem 0;

                        @media (min-width: 768px) {
                            font-size: 1.05rem;
                        }
                    }
                }
            }
        }
    }
}