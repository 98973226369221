section#contact {
    article.container {
        padding: 1rem 1rem 10rem;

        @media(min-width: 768px) {
            padding: 1rem 1rem 13rem;
        }

        .wrap {
            position: relative;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            height: auto;

            .address {
                margin: 1rem 0 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: .7;

                figure {
                    width: 40px;
                    height: 30px;
                    background-image: url('https://icongr.am/clarity/email.svg?size=30&color=000000');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    opacity: .5;
                }

                p {
                    margin: -5px 0 0 .5rem;
                }
            }

            form {

                .input {
                    position: relative;
                    min-height: 40px;
                    margin: 0 0 1.5rem;

                    label {
                        position: absolute;
                        width: 50px;
                        min-height: 40px;
                        z-index: 1;
                        background-position: center;
                        background-repeat: no-repeat;

                        &[for] {
                            opacity: .5;
                            // -webkit-filter: grayscale(100%);
                            // -moz-filter: grayscale(100%);
                            // -ms-filter: grayscale(100%);
                            // -o-filter: grayscale(100%);
                            // filter: gray;
                            transition: .5s;
                        }

                        &[for="name"] {
                            background-image: url('https://icongr.am/material/account.svg?size=30&color=cacaca');
                        }

                        &[for="email"] {
                            background-image: url('https://icongr.am/material/email.svg?size=30&color=cacaca');
                        }

                        &[for="message"] {
                            background-image: url('https://icongr.am/material/lead-pencil.svg?size=30&color=cacaca');
                        }
                    }

                    input,
                    textarea {
                        float: right;
                        width: 85%;
                        min-height: 40px;
                        font-size: 16px;
                        border-radius: 4px;
                        -webkit-appearance: none;
                        border: none;
                        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .15);
                        padding: 0 1rem;
                        transition: .7s;

                        @media(min-width: 768px) {
                            width: 88%;
                        }

                        &:focus {
                            width: 100%;
                            padding: 0 50px;
                            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .25);

                            &+label {
                                opacity: 1;
                                // -webkit-filter: grayscale(0%);
                                // -moz-filter: grayscale(0%);
                                // -ms-filter: grayscale(0%);
                                // -o-filter: grayscale(0%);
                                // filter: none;

                                &[for="name"] {
                                    background-image: url('https://icongr.am/material/account.svg?size=30&color=0FB9B1');
                                }

                                &[for="email"] {
                                    background-image: url('https://icongr.am/material/email.svg?size=30&color=0FB9B1');
                                }

                                &[for="message"] {
                                    background-image: url('https://icongr.am/material/lead-pencil.svg?size=30&color=0FB9B1');
                                }
                            }
                        }
                    }

                    textarea {
                        padding: 1rem;
                        min-height: 200px;

                        &:focus {
                            width: 100%;
                            padding: 1rem 50px;
                        }
                    }
                }

                button {
                    position: absolute;
                    bottom: -65px;
                    right: 0;
                    width: 85%;
                    height: 45px;
                    border: 1px solid rgba(0, 0, 0, .2);
                    border-radius: 4px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, .3);
                    background-color: rgba(255, 255, 255, 1);
                    // transform: translateY(200%);
                    transition: .3s;

                    &:hover {
                        color: rgba(87, 170, 119, 1);
                        border: 1px solid rgba(87, 170, 119, 1);
                        cursor: pointer;
                    }

                    &.can-send {
                        background-color: rgba(87, 170, 119, 1);
                        border: none;
                        color: white;
                    }

                    @media(min-width: 768px) {
                        width: 88%;
                    }
                }
            }


        }
    }
}