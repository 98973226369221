@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&subset=korean');
@import url('https://fonts.googleapis.com/css?family=Oswald:500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,900');


body {
    font: {
        size: 16px;
        family: 'Noto Sans KR', 'Oswald', 'Roboto', sans-serif;
    }
}