section#about {
    // &::after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background-image: url('/assets/about/grid.jpg');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     width: 100%;
    //     height: 100%;
    //     opacity: 0.2;
    //     z-index: -1;
    // }

    article.container {

        border-bottom: 1px solid rgba(0, 0, 0, .1);

        .wrap {
            padding-top: 50px;

            figure {
                text-align: center;
                margin-bottom: 2rem;

                img {
                    display: block;
                    width: 235px;
                    margin: 0 auto;
                    opacity: .6;
                }

                figcaption {
                    font-size: 14px;
                    color: rgba(0, 0, 0, .45);
                    line-height: 2.5;
                }

                @media (min-width: 768px) {
                    & {
                        img {
                            width: 300px;
                        }

                        figcaption {
                            font-size: 18px;
                        }
                    }
                }

                @media (min-width: 1366px) {
                    & {
                        img {
                            width: 350px;
                        }

                        figcaption {
                            font-size: 20px;
                        }
                    }
                }

            }

            .description {
                font-size: 14px;
                font-weight: 200;
                text-align: center;

                @media (min-width: 768px) {
                    font-size: 15px;
                }

                @media (min-width: 1600px) {
                    font-size: 16px;
                }

                .sm {
                    display: block;

                    @media(min-width: 768px) {
                        display: none;
                    }
                }
            }

        }
    }
}