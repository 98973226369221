footer {
    position: relative;
    // height: 10rem;
    // background-color: rgb(78, 78, 78);
    border-top: 1px solid rgba(0, 0, 0, .05);
    text-align: center;
    font-size: 14px;
    padding: 1rem 0;
    color: rgba(0, 0, 0, .4);


    p {
        // @include centered;
    }
}