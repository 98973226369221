section#partners {
    // &::after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background-image: url('/assets/partners/hand.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     width: 100%;
    //     height: 100%;
    //     opacity: 0.1;
    //     z-index: -1;

    //     @media (min-width: 1024px) {
    //         background-image: url('/assets/partners/hand-web.png');
    //     }
    // }

    background-color: rgb(246, 245, 246);

    article.container {
        // padding: 150px 1rem 50px;

        .wrap {
            // position: relative;
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: center;

            ul {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media (min-width: 768px) {
                    flex-direction: row;
                    justify-content: space-around;
                }

                li {
                    margin: 2rem 0;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 200px;
                        max-height: 100%;
                        object-fit: contain;
                    }

                    // &.quoine {
                    //     img {
                    //         max-width: 100%;
                    //     }
                    // }
                }
            }
        }
    }
}