section#team {

    background-color: rgb(246, 245, 246);

    article.container {

        @media(max-width: 767px) {
            // & {
            //     padding: 160px 1rem 50px;
            // }
        }



        .wrap {
            // 서로 삭제하면서 생긴 스타일
            width: 768px;
            margin: 0 auto;

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;


                li {
                    width: 33%;
                    // max-width: 250px;
                    // margin: 0 auto;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media (max-width: 767px) {
                        & {
                            padding: 1rem 0 3rem;

                            &:first-child {
                                width: 100%;
                                max-width: 100%;
                                // padding: 1rem 0;
                            }

                            &:nth-child(2),
                            &:last-child {
                                width: 50%;
                            }
                        }
                    }

                    figure.picture {
                        width: 115px;
                        height: 115px;
                        border-radius: 50%;
                        overflow: hidden;

                        @media(min-width: 768px) {
                            width: 120px;
                            height: 120px;
                        }

                        @media(min-width: 1024px) {
                            width: 140px;
                            height: 140px;
                        }

                        @media(min-width: 1366px) {
                            width: 150px;
                            height: 150px;
                        }

                        img {
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    h4 {
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: bold;
                        padding: 1rem 0 .5rem;

                        @media(min-width: 768px) {
                            font-size: 16px;
                        }
                    }

                    p {
                        font-size: 13px;
                        margin-bottom: 1rem;

                        @media(min-width: 768px) {
                            font-size: 14px;
                        }
                    }

                    a {

                        figure {
                            img {
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
    }

}