main {
    position: relative;
    z-index: 1;
    background-color: rgb(255, 255, 255);
    min-height: 100%;
    // height: 100%;
    // overflow: hidden;

    // &.active {
    //     height: auto;
    //     overflow: initial;
    //     animation: fadeIn 1s .1s both;

    //     @keyframes fadeIn {
    //         from {
    //             opacity: 0;
    //         }

    //         to {
    //             opacity: 1;
    //         }

    //     }
    // }

    // @import './header';

    &>section {
        position: relative;
        z-index: 1;
        width: 100%;
        // max-width: 1024px;
        // min-height: 100vh;
        // margin: -50px auto 0;
        // background-color: rgb(235, 235, 235);
        // border-bottom: 3px solid rgba(93, 141, 163, 0.5);

        &#about {
            min-height: 100vh;
            // margin: -50px auto 0;

            @media (min-width: 768px) {
                // margin-top: -60px;
            }

            &>article.container {
                min-height: 100vh !important;
                padding: 0 1rem !important;
            }
        }

        &:last-child {
            border-bottom: none;
            // min-height: initial;

            // & article.container {
            //     display: flex;
            //     justify-content: center;
            //     padding: 5rem 0 10rem;
            // }
        }

        // @media (min-width: 768px) {
        //     margin-top: -60px;
        // }

        &>article.container {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1024px;
            // min-height: 100vh;
            margin: 0 auto;
            // padding: 50px 1rem 0;
            padding: 1rem 1rem 5rem;

            @media(min-width: 768px) {
                padding: 1rem 1rem 8rem;
            }

            &>.wrap {
                width: 100%;
            }
        }
    }


}