section#services {
    article.container {

        @media(max-width: 1023px) {
            // & {
            //     padding: 180px 1rem 50px;
            // }
        }

        .wrap {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @media(min-width: 768px) {
                // padding-left: 8rem;
            }

            .mining,
            .advisory {
                width: 100%;

                // border: 1px solid darkcyan;

                h1 {
                    font-size: 20px;
                    font-weight: bold;
                    // font-style: italic;
                    opacity: .88;
                    padding: 1rem 0 3rem;
                    text-align: center;

                    @media(min-width: 1024px) {
                        text-align: initial;
                    }
                }

                p {
                    font-size: 16px;
                    // font-style: italic;
                }
            }

            .mining {

                @media(min-width: 1024px) {
                    max-width: 300px;
                    margin-left: 130px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        display: flex;
                        padding-bottom: 1.5rem;
                        width: 50%;

                        @media(max-width: 767px) {
                            justify-content: space-around;
                        }

                        @media(min-width: 768px) and (max-width: 1023px) {
                            width: 25%;
                            justify-content: space-around;
                        }

                        &:last-child .box {
                            @media (min-width: 768px) {
                                padding-left: 5px;
                            }
                        }

                        .box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            figure {
                                flex: 1;
                                width: 60px;
                                display: flex;
                                justify-content: center;


                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;

                                    &.ambrosus {
                                        width: 70px;
                                    }

                                    &.tezos {
                                        height: 55px;
                                    }
                                }
                            }

                            p {
                                padding: 1rem 0;
                            }
                        }

                    }
                }
            }

            .advisory {
                width: 100%;

                @media(min-width: 1024px) {
                    max-width: 350px;
                    margin-left: 80px;
                }

                @media(min-width: 1024px) {
                    margin-left: 80px;
                }

                @media(min-width: 1366px) {
                    overflow: initial;
                    white-space: nowrap;
                }

                h1 {
                    padding-bottom: 1.5rem;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    li {
                        width: 100%;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        // padding-left: 2.5rem;
                        // margin-bottom: 1rem;

                        @media (max-width: 767px) {
                            width: 100%;
                            height: 70px;
                            padding-left: 1.5rem;
                        }

                        @media (min-width: 768px) and (max-width: 1023px) {
                            width: 40%;
                        }



                        figure {
                            min-width: 60px;
                            max-width: 60px;


                            img {
                                width: 100%;
                            }
                        }

                        // P {
                        //     padding-left: 2rem;
                        // }
                    }
                }
            }

            .border {
                width: 50%;
                border-top: 1px solid rgba(0, 0, 0, .1);
                margin: 2rem 0 3.5rem;

                @media(min-width: 1024px) {
                    width: 0;
                    border-left: 1px solid rgba(0, 0, 0, .1);
                    // margin: 0 3rem 0 0;
                }
            }
        }
    }
}