#about>.cube-wrap {
    position: absolute;
}


.cube-wrap {
    // position: absolute;
    position: relative;
    width: 100%;

    .space {
        height: 50px;

        @media(min-width: 768px) {
            height: 60px;
        }
    }

    figure.cube {
        padding: 2rem 0 1rem;
        text-align: center;

        img {
            display: block;
            width: 25px;
            margin: 0 auto;

            &.active {
                animation: roll 1.5s ease-out;
            }
        }

        figcaption {
            font-size: 15px;
            line-height: 2.5;
            font-family: 'Roboto', Arial;
            font-weight: bold;
            letter-spacing: 4px;
            // color: rgb(58, 127, 173);
            color: rgb(149, 189, 216);
            // color: rgb(22, 44, 81);

        }

        @media(min-width: 768px) {
            & {
                img {
                    width: 35px;
                }

                figcaption {
                    // font-size: 20px;
                    line-height: 3;
                }
            }
        }

        @media(min-width: 1024px) {
            & {
                img {
                    width: 35px;
                }

                figcaption {
                    // font-size: 22px;
                    line-height: 3;
                }
            }
        }

        @media(min-width: 1366px) {
            & {
                padding: 3rem 0 1.5rem;
            }
        }

        @keyframes roll {
            from {
                opacity: 0;
                transform: rotateY(0deg);
            }

            to {
                transform: rotateY(1440deg);
            }
        }
    }
}