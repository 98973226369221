header {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 50px;
    box-shadow: 1px 0 18px 0 rgba(0, 0, 0, .1);
    background-color: rgba(255, 255, 255, .9);

    @media(min-width: 768px) {
        height: 60px;
    }

    &.active {
        animation: fadeIn .7s .1s both;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }



    .container {
        width: 100%;
        height: 100%;
        max-width: 1024px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        @media(min-width: 1366px) {
            // justify-content: center;
        }

        figure {
            width: 40px;
            margin-left: 1rem;
            z-index: 1;

            &.active {
                animation: fadeLeft 1.5s both;

                @keyframes fadeLeft {
                    from {
                        transform: translate(-30px, 0);
                        opacity: 0;
                    }

                    to {
                        transform: translate(0, 0);
                        opacity: 1;
                    }
                }
            }

            img {
                width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }

        nav {

            ul {
                display: flex;

                li {
                    position: relative;
                    padding: 0 1rem;
                    font-size: 13px;

                    &#blog {
                        color: rgb(22, 44, 81);
                        font-weight: bold;

                        // @media (max-width: 768px) {

                        //     a {
                        //         opacity: .5;
                        //     }
                        // }
                    }

                    &.active {
                        color: rgb(58, 127, 173);
                    }

                    &.reveal {
                        @keyframes fadeDown {
                            from {
                                transform: translate(0, -50px);
                                opacity: 0;
                            }

                            to {
                                transform: translate(0, 0);
                                opacity: 1;
                            }
                        }

                        &:first-child {
                            animation: fadeDown .7s .1s both;
                        }

                        &:nth-child(2) {
                            animation: fadeDown .7s .2s both;
                        }

                        &:nth-child(3) {
                            animation: fadeDown .7s .3s both;
                        }

                        &:nth-child(4) {
                            animation: fadeDown .7s .4s both;
                        }

                        &:nth-child(5) {
                            animation: fadeDown .7s .5s both;
                        }

                        &:nth-child(6) {
                            animation: fadeDown .7s .6s both;
                        }

                        &:last-child {
                            animation: fadeDown .7s .7s both;
                        }
                    }

                    @media(min-width: 1367px) {

                        & {
                            padding: .5rem 1rem;
                        }

                        & a {
                            opacity: .5;
                            transition: .5s;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        &#blog a {
                            opacity: 1;
                        }

                        & a::before,
                        & a::after {
                            position: absolute;
                            content: '';
                            width: 0%;
                            height: 2px;
                            opacity: 0;
                            background-color: rgba(0, 0, 0, .1);
                            transition: .3s;
                        }

                        & a::before {
                            top: 0;
                            left: 0;
                        }

                        & a::after {
                            bottom: 0;
                            right: 0;
                        }

                        & a:hover::before,
                        & a:hover::after {
                            opacity: 1;
                            width: 100%;
                        }
                    }
                }
            }

            @media(max-width: 1023px) {
                & {
                    position: absolute;
                    top: 50px;
                    width: 100%;
                    padding-bottom: 1rem;
                    // background-color: #E5E5E5;
                    background-color: rgba(255, 255, 255, .9);
                    transform: scaleY(0);
                    transform-origin: top;
                    transition: 0.7s;

                    &.active {
                        padding-top: 50px;
                        margin-top: -50px;
                        transform: scaleY(1);
                    }

                    ul {
                        display: initial;
                        padding: 1rem 0;

                        li {
                            text-align: center;
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }

        .hamburger {
            position: absolute;
            right: 1rem;
            z-index: 1;

            &.active {
                animation: fadeRight 1.5s both;

                @keyframes fadeRight {
                    from {
                        transform: translate(30px, 0);
                        opacity: 0;
                    }

                    to {
                        transform: translate(0, 0);
                        opacity: 1;
                    }
                }
            }

            @media(min-width: 1024px) {
                display: none;
            }
        }
    }


}